import { httpMethods } from '../../Globals/Constants';
import Http from '../../Globals/Http';


const get		= async ()						=> await Http.request('clients');
const getById	= async (id)					=> await Http.request(`client/${id}`);

const add		= async (data)					=> await Http.request('client', httpMethods.POST, data);
const update	= async (id, data)				=> await Http.request(`client/${id}`, httpMethods.PUT, data);


export {
	get,
	getById,
	add,
	update
}