import { httpMethods } from '../../Globals/Constants';
import Http from '../../Globals/Http';


const get		= async ()			=> await Http.request('countries');
const getById	= async (id)		=> await Http.request(`country/${id}`);

const add		= async (data)		=> await Http.request('country', httpMethods.POST, data);
const update	= async (id, data)	=> await Http.request(`country/${id}`, httpMethods.PUT, data);


export {
	get,
	getById,
	add,
	update
}