import React, {useEffect,useState} from 'react';

// components
import Template from '../../Components/Template';
import Loader from '../../Components/Loader';

// helpers
import {ls} from '../../Globals/Localstorage';

// styles
import './styles.css';

const DashboardScreen = () => {
	const [loading, setLoading] = useState(true);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
		};
		await dataGet();
		setLoading(false);
	}, []);
	return (
		<Template>
			{loading ? <Loader /> :
				<div className="dashboard-container">
					<div className="header">
						<h4>Статистика</h4>
					</div>
					<div className="blocks">
						<div className="block-wrap">
							<div className="block block-small">
								<b>Средний чек</b>
								<div className="chart">
								</div>
							</div>
						</div>
						<div className="block-wrap">
							<div className="block block-small">
								<b>Количество заказов</b>
								<div className="chart">
								</div>
							</div>
						</div>
						<div className="block-wrap">
							<div className="block block-small">
								<b>Количество отмен</b>
								<div className="chart">
								</div>
							</div>
						</div>
					</div>
				</div>
			}
		</Template>
	);
};

export default DashboardScreen;