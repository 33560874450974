import { httpMethods } from '../../Globals/Constants';
import Http from '../../Globals/Http';


const get		= async ()			=> await Http.request('cities');
const getById	= async (id)		=> await Http.request(`city/${id}`);

const add		= async (data)		=> await Http.request('city', httpMethods.POST, data);
const update	= async (id, data)	=> await Http.request(`city/${id}`, httpMethods.PUT, data);


export {
	get,
	getById,
	add,
	update
}