import React, {useEffect,useState} from 'react';
import {Link} from 'react-router-dom';

// components
import Template from '../../Components/Template';
import Loader from '../../Components/Loader';
import DataGrid,{dgDataPrepare} from '../../Components/DataGrid';
import NotFound from '../../Components/NotFound';

// models
import {Languages} from './../../Models';

const LanguagesScreen = () => {
	const [langueges, setLanguages] = useState(null);
	const [dataFull, setDataFull] = useState(null);
	useEffect(() => {
		const pagesGet = async () => {
			const d = await Languages.get();
			setLanguages(dataSet(d));
			setDataFull(d);
		};
		pagesGet();
	}, []);
	const dataSet = (data) => dgDataPrepare(data, 'languages', ['_id','name','nameRussian','code']);
	return (
		<Template>
			{langueges === null ? <Loader /> :
				<DataGrid
					title={'Языки'}
					keys={['Название','На русском','Код']}
					link={'language'}
					data={langueges}
					dataFull={dataFull}
					controlAdd={<Link to={'/language'}>Добавить</Link>}
					notFound={<NotFound />} />
			}
		</Template>
	);
};

export default LanguagesScreen;