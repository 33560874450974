import React, {useEffect,useState} from 'react';

// components
import Template from '../../Components/Template';
import Loader from '../../Components/Loader';
import Back from '../../Components/Back';
import Alert,{alertShow} from '../../Components/Alert';
import {useInput} from '../../Components/Hooks/Input';

// models
import {Cities,Countries} from './../../Models';

// styles
import './styles.css';

const CityScreen = (props) => {
	const [loading, setLoading] = useState(true);
	const [id] = useState(props.match.params.id||0);
	const {value:name, setValue:setName, bind:bindName} = useInput('');
	const {value:countryId, setValue:setCountryId, bind:bindCountryId} = useInput('');
	const {value:countryName, setValue:setCountryName, bind:bindCountryName} = useInput('');
	const {value:code, setValue:setCode, bind:bindCode} = useInput('');

	const [countries, setCountries] = useState([]);

	useEffect(() => {
		(async () => {
			const countries = await Countries.get();
			setCountries(countries);
			if (id) {
				const d = await Cities.getById(id);
				const city = d;
				setName(city.name);
				setCode(city.code);
				setCountryId(city.countryId);
				setCountryName(city.countryName);
			}
			setLoading(false);
		})();
	}, [id,setName,setCode,setLoading]);
	const save = async (e) => {
		e.preventDefault();
		const data = {name,code,countryId,countryName};
		if (id === 0) {
			const res = await Cities.add(data);
			if (res) alertShow('Операция прошла успешно!');
			else alertShow('<b>Ошибка!</b><br/>При сохранении данных возникла ошибка', true);
		}
		else {
			const res = await Cities.update(id, data);
			if (res) alertShow('Операция прошла успешно!');
			else alertShow('<b>Ошибка!</b><br/>При сохранении данных возникла ошибка', true);
		}
	}
	const selectHandle = (e) => {
		const country = countries.find(f => f._id === e.target.value);
		setCountryId(country._id);
		setCountryName(country.name);
	}
	return (
		<Template>
			{loading ? <Loader /> :
				<form onSubmit={save}>
					<Back title={id === 0 ? 'Добавить город' : 'Редактирование города'} link='cities'
						controlAdd={<a href="/city" className="add">Добавить</a>}  />
					<div className="form-container">
						<div className="cell">
							<label>
								<span>Страна</span>
								<select className="select-minimal" onChange={selectHandle} value={countryId} required>
									<option value=""></option>
									{countries.map((v,i) => <option key={i} value={v._id}>{v.name}</option>)}
								</select>
							</label>
						</div>
						<div className="cell">
							<label>
								<span>Название</span>
								<input type="text" {...bindName} placeholder="Название" required />
							</label>
						</div>
						<div className="cell">
							<label>
								<span>Код</span>
								<input type="text" {...bindCode} placeholder="Код" required />
							</label>
						</div>
						<div className="buttons">
							<button type="submit">Сохранить</button>
						</div>
					</div>
				</form>
			}
			<Alert />
		</Template>
	);
};

export default CityScreen;