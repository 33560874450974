const isDeveloperMode = process.env.NODE_ENV !== 'production';
const url = isDeveloperMode ? 'http://localhost:8041' : 'https://core.pastilapp.ru';

const API = {
	url			: `${url}/api/v1/`,
	key			: 'J<DxyN+YT^$d_//=eq{^6?/@(FSd+5{:D,/BUcqAH]{Okl281.G>?rYf4?ar-KN',
	version		: '1.0.1'
};

const httpMethods = {
	GET		: 'GET',
	POST	: 'POST',
	PUT		: 'PUT',
	DELETE	: 'DELETE'
};

const commonStatus = {
	IN_ACTIVE	: 0,
	ACTIVE		: 1
};
const commonStatusName = ['Не активен','Активен'];

const userStatus = {
	ACTIVE		: 1,
	IN_ACTIVE	: 2,
	BAN			: 3,
	DELETED		: 4
};
const userStatusName = ['','Активен','Не активен','Заблокирован','Удален'];

const clientStatus = {
	ACTIVE		: 1,
	IN_ACTIVE	: 2,
	BAN			: 3,
	DELETED		: 4
};
const clientStatusName = ['','Активен','Не активен','Заблокирован','Удален'];


export {
	API,
	httpMethods,
	commonStatus,
	commonStatusName,
	userStatus,
	userStatusName,
	clientStatus,
	clientStatusName
};