import React, {useEffect,useState} from 'react';
import {Link} from 'react-router-dom';

// components
import Template from '../../Components/Template';
import Loader from '../../Components/Loader';
import DataGrid,{dgDataPrepare} from '../../Components/DataGrid';
import NotFound from '../../Components/NotFound';

// models
import {Cities} from './../../Models';

const CitiesScreen = () => {
	const [cities, setCities] = useState(null);
	const [dataFull, setDataFull] = useState(null);
	useEffect(() => {
		const pagesGet = async () => {
			const d = await Cities.get();
			setCities(dataSet(d));
			setDataFull(d);
		};
		pagesGet();
	}, []);
	const dataSet = (data) => dgDataPrepare(data, 'cities', ['_id','name','countryName','code']);
	return (
		<Template>
			{cities === null ? <Loader /> :
				<DataGrid
					title={'Города'}
					keys={['Название','Страна','Код']}
					link={'city'}
					data={cities}
					dataFull={dataFull}
					controlAdd={<Link to={'/city'}>Добавить</Link>}
					notFound={<NotFound />} />
			}
		</Template>
	);
};

export default CitiesScreen;