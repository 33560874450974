import React, {useEffect,useState} from 'react';

// plug-ins
import Moment from 'moment';
import 'moment/locale/ru';

// components
import Template from '../../Components/Template';
import Loader from '../../Components/Loader';
import Back from '../../Components/Back';
import Alert,{alertShow} from '../../Components/Alert';
import {useInput} from '../../Components/Hooks/Input';
import NotFound from '../../Components/NotFound';

// models
import {Clients} from './../../Models';

// helpers
import {ls} from '../../Globals/Localstorage';

// globals
import {clientStatus,clientStatusName} from '../../Globals/Constants';

// styles
import './styles.css';

const ClientScreen = (props) => {
	const [id] = useState(props.match.params.id||0);
	const [loading, setLoading] = useState(true);
	const [client, setClient] = useState(null);
	const {value:name, setValue:setName, bind:bindName} = useInput('');
	const {value:commentStatus, setValue:setCommentStatus, bind:bindCommentStatus} = useInput('');
	const [status, setStatus] = useState(clientStatus.ACTIVE);
	useEffect(async () => {
		Moment.locale('ru');
		const user = ls('user');
		if (!user) {
			window.location.href = '/login';
			return;
		}
		const clientGet = async () => {
			if (id) {
				const client = await Clients.getById(id);
				if (client) {
					setName(client.name);
					setCommentStatus(user.commentStatus);
					setStatus(client.status);
					setClient(client);
				}
			}
			setLoading(false);
		};
		await clientGet();
	}, [id]);
	const selectHandle = (e) => setStatus(parseInt(e.target.value));
	const save = async (e) => {
		e.preventDefault();
		const data = {
			name,
			commentStatus
		};
		if (id) data.status = status;
		try {
			if (id === 0) {
				await Clients.add(data);
				alertShow('Пользователь добавлен');
			} else {
				await Clients.update(id, data);
				alertShow('Данные обновлены');
			}
		} catch (ex) {
			console.log(666, ex);
			alertShow('<b>Ошибка!</b><br/>При сохранении данных возникла ошибка', true);
		}		
	}
	return (
		<Template>
			{loading ? <Loader /> :
				<form onSubmit={save}>
					<Back title={id ? 'Редактирование пользователя' : 'Добавление пользователя'} link='clients'
						controlAdd={<a href="/client" className="add">Добавить</a>}  />
					{id && client === null ? <NotFound /> :
						<div className="form-container">
							<div className="cell">
								<label>
									<span>Имя</span>
									<input type="text" {...bindName} placeholder="Имя" required />
								</label>
							</div>
							{id ? 
								<div className="cell">
									<label>
										<span>Статус</span>
										<select className="select-minimal" onChange={selectHandle} value={status}>
											{clientStatusName.map((v,i) => i === 0 ? null : <option key={i} value={i}>{v}</option>)}
										</select>
									</label>
								</div> : null}
							{status !== clientStatus.ACTIVE ?
								<>
									<div className="notice">
										<span>Внимание! Вы выбрали статус пользователя <b>{clientStatusName[status]}</b>. Необходимо указать причину смены статуса</span>
									</div>
									<div className="cell">
										<label>
											<span>Описание блокировки</span>
											<textarea name="commentStatus" placeholder="Опишите причину блокировки оператора" {...bindCommentStatus} required></textarea>
										</label>
									</div>
								</> : null}
							{id ?
								<div className="cell-oneline">
									<div className="cell">
										<label>
											<span>Дата регистрации</span>
											<div>{Moment(client.createdAt).format('lll')}</div>
										</label>
									</div>
									<div className="cell">
										<label>
											<span>Последние изменения</span>
											<div>{Moment(client.updatedAt).format('lll')}</div>
										</label>
									</div>
								</div> : null}
							<div className="buttons">
								<button type="submit">Сохранить</button>
							</div>
						</div>
					}
				</form>
			}
			<Alert />
		</Template>
	);
};

export default ClientScreen;